import _prepareStackTrace from "./prepareStackTrace";
var exports = {};
var prepareStackTrace = _prepareStackTrace;

exports = function () {
  Error.getStackTrace = function (throwable) {
    return throwable.stack;
  };

  return {
    prepareStackTrace: prepareStackTrace
  };
};

export default exports;