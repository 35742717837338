import * as _o2 from "o3";

var _o = "default" in _o2 ? _o2.default : _o2;

import _Frame from "./Frame";
import * as _u2 from "u3";

var _u = "default" in _u2 ? _u2.default : _u2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Class = _o.Class,
    Frame = _Frame,
    cache = _u.cache;
var FrameStringParser = Class(Object, {
  prototype: {
    stackParser: null,
    frameParser: null,
    locationParsers: null,
    constructor: function (options) {
      Class.prototype.merge.call(this || _global, options);
    },
    getFrames: function (frameStrings, functionValues) {
      var frames = [];

      for (var index = 0, length = frameStrings.length; index < length; ++index) frames[index] = this.getFrame(frameStrings[index], functionValues[index]);

      return frames;
    },
    getFrame: function (frameString, functionValue) {
      var config = {
        frameString: frameString,
        functionValue: functionValue
      };
      return new Frame(config);
    }
  }
});
exports = {
  getClass: cache(function () {
    return FrameStringParser;
  }),
  getInstance: cache(function () {
    var FrameStringParser = this.getClass();
    var instance = new FrameStringParser();
    return instance;
  })
};
export default exports;