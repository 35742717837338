import * as _es2 from "capability/es5";

var _es = "default" in _es2 ? _es2.default : _es2;

import * as _capability2 from "capability";

var _capability = "default" in _capability2 ? _capability2.default : _capability2;

import _v from "./v8";
import _index from "./non-v8/index";
import _unsupported from "./unsupported";
var exports = {};
_es;
var capability = _capability;
var polyfill;
if (capability("Error.captureStackTrace")) polyfill = _v;else if (capability("Error.prototype.stack")) polyfill = _index;else polyfill = _unsupported;
exports = polyfill();
export default exports;