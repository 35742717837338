import * as _u2 from "u3";

var _u = "default" in _u2 ? _u2.default : _u2;

import _prepareStackTrace from "./prepareStackTrace";
var exports = {};
var cache = _u.cache,
    prepareStackTrace = _prepareStackTrace;

exports = function () {
  Error.captureStackTrace = function (throwable, terminator) {
    Object.defineProperties(throwable, {
      stack: {
        configurable: true,
        get: cache(function () {
          return (Error.prepareStackTrace || prepareStackTrace)(throwable, []);
        })
      },
      cachedStack: {
        configurable: true,
        writable: true,
        enumerable: false,
        value: true
      }
    });
  };

  Error.getStackTrace = function (throwable) {
    if (throwable.cachedStack) return throwable.stack;
    var stack = (Error.prepareStackTrace || prepareStackTrace)(throwable, []);

    try {
      Object.defineProperties(throwable, {
        stack: {
          configurable: true,
          writable: true,
          enumerable: false,
          value: stack
        },
        cachedStack: {
          configurable: true,
          writable: true,
          enumerable: false,
          value: true
        }
      });
    } catch (nonConfigurableError) {}

    return stack;
  };

  return {
    prepareStackTrace: prepareStackTrace
  };
};

export default exports;