var exports = {};

var prepareStackTrace = function (throwable, frames, warnings) {
  var string = "";
  string += throwable.name || "Error";
  string += ": " + (throwable.message || "");
  if (warnings instanceof Array) for (var warningIndex in warnings) {
    var warning = warnings[warningIndex];
    string += "\n   # " + warning;
  }

  for (var frameIndex in frames) {
    var frame = frames[frameIndex];
    string += "\n   at " + frame.toString();
  }

  return string;
};

exports = prepareStackTrace;
export default exports;