import * as _o2 from "o3";

var _o = "default" in _o2 ? _o2.default : _o2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Class = _o.Class,
    abstractMethod = _o.abstractMethod;
var Frame = Class(Object, {
  prototype: {
    init: Class.prototype.merge,
    frameString: undefined,
    toString: function () {
      return (this || _global).frameString;
    },
    functionValue: undefined,
    getThis: abstractMethod,
    getTypeName: abstractMethod,
    getFunction: function () {
      return (this || _global).functionValue;
    },
    getFunctionName: abstractMethod,
    getMethodName: abstractMethod,
    getFileName: abstractMethod,
    getLineNumber: abstractMethod,
    getColumnNumber: abstractMethod,
    getEvalOrigin: abstractMethod,
    isTopLevel: abstractMethod,
    isEval: abstractMethod,
    isNative: abstractMethod,
    isConstructor: abstractMethod
  }
});
exports = Frame;
export default exports;